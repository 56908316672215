import React from "react";
import Layout from "../Components/layout";
import Shippinginfo from "../Components/shippinginfo";
import { Seo } from "../Components/seo";


export default function Shipping() {
  return (
    <Layout>
      <Shippinginfo />
    </Layout>
  )
}

export const Head = () => (
  <Seo />
)