import React, { useState, useEffect } from "react";
import { getCookie } from "../Utils/Cookies";
import Checkbox from "react-custom-checkbox";
import * as Icon from "react-icons/fi";
import { setCookie } from "../Utils/Cookies";
export default function Shippinginfo() {
  const [addressInfo, setAddressInfo] = useState();
  const [items, setItems] = useState([]);
  const [shipping, setShipping] = useState();
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
      let cart = getCookie("cart");
      setItems(JSON.parse("["+cart+"]"));
      let shipping = getCookie("shipping");
      if (shipping)
      {
        setShipping(JSON.parse(shipping));
      }
    }, []);
    const calcTotal = function() {
        let total = 0;
        items.forEach((item) => {
          total = total + (parseInt(item.price) * parseInt(item.qty));
        })
        return total;
    }
  const states = [
    {
        "code": "AA",
        "name": "Armed Forces Americas (except Canada)"
    },
    {
        "code": "AE",
        "name": "Armed Forces"
    },
    {
        "code": "AK",
        "name": "Alaska"
    },
    {
        "code": "AL",
        "name": "Alabama"
    },
    {
        "code": "AP",
        "name": "Armed Forces Pacific"
    },
    {
        "code": "AR",
        "name": "Arkansas"
    },
    {
        "code": "AS",
        "name": "American Samoa"
    },
    {
        "code": "AZ",
        "name": "Arizona"
    },
    {
        "code": "CA",
        "name": "California"
    },
    {
        "code": "CO",
        "name": "Colorado"
    },
    {
        "code": "CT",
        "name": "Connecticut"
    },
    {
        "code": "DC",
        "name": "District of Columbia"
    },
    {
        "code": "DE",
        "name": "Delaware"
    },
    {
        "code": "FL",
        "name": "Florida"
    },
    {
        "code": "FM",
        "name": "Federated States of Micronesia"
    },
    {
        "code": "GA",
        "name": "Georgia"
    },
    {
        "code": "GU",
        "name": "Guam"
    },
    {
        "code": "HI",
        "name": "Hawaii"
    },
    {
        "code": "IA",
        "name": "Iowa"
    },
    {
        "code": "ID",
        "name": "Idaho"
    },
    {
        "code": "IL",
        "name": "Illinois"
    },
    {
        "code": "IN",
        "name": "Indiana"
    },
    {
        "code": "KS",
        "name": "Kansas"
    },
    {
        "code": "KY",
        "name": "Kentucky"
    },
    {
        "code": "LA",
        "name": "Louisiana"
    },
    {
        "code": "MA",
        "name": "Massachusetts"
    },
    {
        "code": "MD",
        "name": "Maryland"
    },
    {
        "code": "ME",
        "name": "Maine"
    },
    {
        "code": "MH",
        "name": "Marshall Islands"
    },
    {
        "code": "MI",
        "name": "Michigan"
    },
    {
        "code": "MN",
        "name": "Minnesota"
    },
    {
        "code": "MO",
        "name": "Missouri"
    },
    {
        "code": "MP",
        "name": "Northern Mariana Islands"
    },
    {
        "code": "MS",
        "name": "Mississippi"
    },
    {
        "code": "MT",
        "name": "Montana"
    },
    {
        "code": "NC",
        "name": "North Carolina"
    },
    {
        "code": "ND",
        "name": "North Dakota"
    },
    {
        "code": "NE",
        "name": "Nebraska"
    },
    {
        "code": "NH",
        "name": "New Hampshire"
    },
    {
        "code": "NJ",
        "name": "New Jersey"
    },
    {
        "code": "NM",
        "name": "New Mexico"
    },
    {
        "code": "NV",
        "name": "Nevada"
    },
    {
        "code": "NY",
        "name": "New York"
    },
    {
        "code": "OH",
        "name": "Ohio"
    },
    {
        "code": "OK",
        "name": "Oklahoma"
    },
    {
        "code": "OR",
        "name": "Oregon"
    },
    {
        "code": "PA",
        "name": "Pennsylvania"
    },
    {
        "code": "PR",
        "name": "Puerto Rico"
    },
    {
        "code": "PW",
        "name": "Palau"
    },
    {
        "code": "RI",
        "name": "Rhode Island"
    },
    {
        "code": "SC",
        "name": "South Carolina"
    },
    {
        "code": "SD",
        "name": "South Dakota"
    },
    {
        "code": "TN",
        "name": "Tennessee"
    },
    {
        "code": "TX",
        "name": "Texas"
    },
    {
        "code": "UT",
        "name": "Utah"
    },
    {
        "code": "VA",
        "name": "Virginia"
    },
    {
        "code": "VI",
        "name": "Virgin Islands"
    },
    {
        "code": "VT",
        "name": "Vermont"
    },
    {
        "code": "WA",
        "name": "Washington"
    },
    {
        "code": "WI",
        "name": "Wisconsin"
    },
    {
        "code": "WV",
        "name": "West Virginia"
    },
    {
        "code": "WY",
        "name": "Wyoming"
    }
]
  return (
    <div style={{height: `100vh`, display: `flex`, justifyContent: `center`, alignItems: `center`}}>
    <div style={{maxWidth: `400px`, color: `#dddddd`, minHeight: `65vh`}}>
    <h1 style={{fontSize: `20px`, color: `#e37100`, textAlign: `center`, marginBottom: `60px`}}>Subtotal: ${calcTotal()}</h1>
    {shipping && <><h1 style={{fontSize: `20px`, color: `#e37100`, marginBottom: `30px`}}>Previous Shipping Address</h1>
    <p style={{margin: `0px`}}>{shipping.line1}</p>
    <p style={{margin: `0px`}}>{shipping.line2}</p>
    <p>{shipping.city}, {shipping.state} {shipping.zip}</p>
    <div style={{display: `flex`, alignItems: `center`, marginBottom: `50px`}}>         
       <div style={{marginTop: `12px`}}><Checkbox
          checked={checked}
          icon={
            <span
              style={{
                marginLeft: `2px`,
                marginTop: `3px`,
                flex: 1,
              }}
            >
              <Icon.FiCheck id="checked" color="black" size={15} />
            </span>
          }
          borderColor="black"
          borderWidth={1}
          borderRadius={1}
          size={14}
          onChange={(value) => {
            setChecked(value);
          }}
        /></div>
       <div style={{height: `14px`, marginLeft: `10px`}}>Use this address</div> </div>
       </>}
      {!checked && <><h1 style={{fontSize: `20px`, color: `#e37100`, textAlign: `center`, marginBottom: `30px`}}>Shipping Address</h1>
    <input style={{marginBottom: `10px`}} autoFocus type="text" id="address1" name="address1" placeholder="Address Line 1" onChange={(e) => {
                setAddressInfo({ ...addressInfo, line1: e.target.value });
              }}required />
    <input style={{marginBottom: `10px`}} type="text" id="address2" name="address2" onChange={(e) => {
                setAddressInfo({ ...addressInfo, line2: e.target.value });
              }} placeholder="Address Line 2" />
    <input type="text" id="city" name="city" onChange={(e) => {
                setAddressInfo({ ...addressInfo, city: e.target.value });
              }} placeholder="City" required />
    <select
              style={{marginBottom: `10px`}}
              onChange={(e) => {
                setAddressInfo({ ...addressInfo, state: e.target.value, stateName: e.target.options[e.target.selectedIndex].text });
              }}
              id="stateDropdown"
              className="courseLargeDropdown focusOutline"
              defaultValue="state"
            >
              <option value="state">US State</option>
              {states &&
                states.map(({ code, name }) => (
                  <option key={code} value={code}>
                    {name}
                  </option>
                ))}
            </select>
    <input type="text" id="zip" name="zip" placeholder="Zip Code" onChange={(e) => {
                setAddressInfo({ ...addressInfo, zip: e.target.value });
              }} required /></>}
    <button
        onClick={() => {
            if (checked)
            {
                window.location.href = "/checkout"
            }
            else{
                if (!addressInfo || !addressInfo.line1 || !addressInfo.city || !addressInfo.state || !addressInfo.zip)
                {
                    setError(true);
                }
                else{
                    setCookie("shipping", JSON.stringify(addressInfo), 1)
                    window.location.href = "/checkout"
                }
            }
           
        }}
        style={{marginTop: `20px`, width: `100%`, height: `40px`, fontSize: `20px`, paddingBottom: `2px`, color: `white`}}
      >
          Next
      </button>
      {error && <div style={{color: `red`, marginTop: `20px`}}>Address Line 1, City, State, and Zip Code are all required.</div>}
    </div>
    </div>
  );
}
